import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cta = ({ href }) => (
  <a href={href} alt="Renfort Solutions">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA RENFORT SOLUTIONS.png"
      alt="Renfort Solutions"
    />
  </a>
);

export default cta;
